<template>
  <div class="info">
    <div class="content">
      <img
        class="title"
        src="https://fs.zhbim.com/imgs/active/618/info-title.png"
        alt=""
      />
      <p class="hint">
        正确填写信息可顺利领奖哟~<br />
        信息提交截止时间：<span>2022年6月30日23:59；</span><br />
        逾期将无法领奖。
      </p>
      <div class="fill-out-wrap">
        <p class="fill-out-title">填写领奖信息</p>
        <mt-field
          :disabled="disable"
          label="姓  名："
          placeholder="请输入联系人名称"
          v-model="form.name"
          :attr="{ maxlength: 10 }"
        ></mt-field>
        <mt-field
          :disabled="disable"
          label="电  话："
          placeholder="请填写正确的联系方式以便成功领奖"
          v-model="form.mobilePhone"
          :attr="{ maxlength: 11 }"
        ></mt-field>
        <mt-field
          :disabled="disable"
          label="地  址："
          placeholder="请输入您的详细地址"
          v-model="form.city"
          :attr="{ maxlength: 50 }"
        ></mt-field>
      </div>
      <img
        @click="editInfo"
        class="btn"
        src="https://fs.zhbim.com/imgs/active/618/info-btn.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
import { editReceiveInfo, getReceiveInfo } from "@/api/lottery";
export default {
  data() {
    return {
      id: null,
      status: null,
      disable: false,
      form: {
        name: "",
        mobilePhone: "",
        city: "",
      },
    };
  },
  created() {
    this.id = Number(this.$route.query.id);
    this.status = Number(this.$route.query.status);
    console.log(this.id, this.status);
    this.timeDifference();
    if (this.status === 1) {
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      getReceiveInfo(this.id).then((res) => {
        console.log(res);
        this.form = res;
      });
    },
    editInfo() {
      if (this.disable) {
        Toast({
          message: "已超过修改时间",
          duration: 3 * 1000,
        });
        return;
      }
      if (this.verify(this.form)) return;
      editReceiveInfo(this.form).then((res) => {
        if (res.returnCode === "SUCCESS") {
          Toast({
            message: "提交成功",
            duration: 3 * 1000,
          });
          setTimeout(() => {
            this.$router.replace({
              path: "/myPrize",
            });
          }, 1000);
        }
      });
    },
    verify(data) {
      if (!data.name) {
        Toast({
          message: "请输入姓名",
          duration: 3 * 1000,
        });
        return true;
      }
      if (!data.mobilePhone) {
        Toast({
          message: "请输入手机号",
          duration: 3 * 1000,
        });
        return true;
      } else {
        let iphoneReg =
          /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
        if (!iphoneReg.test(data.mobilePhone)) {
          Toast({
            message: "请输入正确手机号码",
            duration: 3 * 1000,
          });
          return true;
        }
      }
      if (!data.city) {
        Toast({
          message: "请输入详细地址",
          duration: 3 * 1000,
        });
        return true;
      }
      return false;
    },
    timeDifference() {
      let timestamp = new Date().getTime();
      let timestamp1 = this.getTargetTime("2022-07-01 00:00:00");
      let deta = Number(timestamp) - Number(timestamp1);
      if (deta >= 0) {
        this.disable = true;
      }
    },
    //获取指定时间戳
    getTargetTime(t) {
      var d = t.split(" ")[0],
        h = t.split(" ")[1],
        date = new Date();

      date.setYear(d.split("-")[0]);
      date.setMonth(d.split("-")[1] - 1);
      date.setDate(d.split("-")[2]);

      date.setHours(h.split(":")[0]);
      date.setMinutes(h.split(":")[1]);
      date.setSeconds(h.split(":")[2]);

      return date.getTime();
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/image/game-bg.jpg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 2.12rem;
  box-sizing: border-box;
  .content {
    margin: 0 auto;
    width: 6.69rem;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
    padding: 0.5rem 0.33rem;
    box-sizing: border-box;
    .title {
      width: 2.25rem;
      height: 0.6rem;
    }
    .hint {
      font-size: 0.24rem;
      font-weight: 500;
      color: #888888;
      // line-height: 1.05rem;
      margin-bottom: 0.6rem;
      margin-top: 0.4rem;
      text-align: left;
      span {
        color: red;
      }
    }
    .fill-out-wrap {
      text-align: left;
      .fill-out-title {
        font-size: 0.2rem;
        font-weight: 500;
        color: #251876;
        margin-bottom: 0.3rem;
      }
      .mint-field .mint-cell-title {
        width: 1rem;
      }
      .mint-cell-wrapper {
        font-size: 0.24rem;
      }
    }
    .btn {
      width: 2.94rem;
      height: 0.75rem;
    }
  }
}
</style>
<style lang="scss">
.fill-out-wrap {
  .mint-field {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    margin-bottom: 0.3rem;
    background: none;
    .mint-cell-title {
      width: 1rem;
    }
    .mint-cell-text {
      vertical-align: bottom;
    }
    .mint-cell-wrapper {
      font-size: 0.24rem;
      background: none;
    }
    .mint-field-core {
      line-height: normal;
      font-size: 0.24rem;
    }
  }
}
</style>