// 路由守卫处理
import router from './router'
import {
  wxLogin,
  channelPoint
} from "@/api/login";
import {
  setToken,
  getToken,
  setRefshToken,
  setGroupCode,
  setUser
} from '@/utils/auth';
import store from "@/store";
var state, resetUrl;

router.beforeEach(async (to, from, next) => {
  if (to.query.state) { //区分不同渠道链接
    state = to.query.state;
    resetUrl = process.env.VUE_APP_BASE_RefshHref + '&state=' + state + '#wechat_redirect'
  } else {
    resetUrl = process.env.VUE_APP_BASE_RefshHref + process.env.VUE_APP_BASE_RefshHrefState
  }
  if (to.query.groupCode) { //如果带有战队信息，将战队信息存储
    setGroupCode(to.query.groupCode)
    store.commit('SET_GROUPCODE', to.query.groupCode)
  }
  if (to.query.code && !getToken()) { //已授权未登录（授权后微信会在页面地址自动拼接code）
    wxLogin({
      code: to.query.code,
      state: 'public'
    }).then((res) => {
      setToken(res.token.access_token);
      setRefshToken(res.token.refresh_token);
      setUser(res.userInfo);
      store.commit('SET_TOKEN', res.token.access_token)
      store.commit('SET_REFSHTOKEN', res.token.refresh_token)
      if (state) {
        channelPoint({
          linkUrl: state,
          remark: '618活动',
        }).then((res) => {
          console.log('埋点成功');
        })
      }
      next();
    }).catch((res) => {
      if (state) {
        channelPoint({
          linkUrl: state,
          remark: '618活动',
        }).then((res) => {
          console.log('埋点成功');
        })
      }
      next({
        path: '/'
      })
    })
  } else { //正常链接进入页面（两种情况：一已授权已登录，二未授权未登录）
    if (getToken()) {
      next();
    } else {
      location.replace(resetUrl);
      // next();
    }
  }
})

router.afterEach((to, from) => {
  window.scrollTo(0, 0);
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
})