import {
  getGroupCode,
  removeGroupCode
} from '@/utils/auth'
import {
  joinGroup,
  myGroup
} from '@/api/game'
import {
  Toast
} from 'mint-ui';
const user = {
  state: {
    token: null,
    refshToken: null,
    groupCode: null
  },
  mutations: {
    SET_TOKEN: (state, payload) => {
      state.token = payload
    },
    SET_REFSHTOKEN: (state, payload) => {
      state.refshToken = payload
    },
    SET_GROUPCODE: (state, payload) => {
      state.groupCode = payload
    },
  },

  actions: {
    //加入团队
    getInvite({
      state,
      commit
    }) {
      return new Promise((resolve, reject) => {
        if (state.groupCode || getGroupCode()) {
          joinGroup({
            groupCode: state.groupCode || getGroupCode()
          }).then((res) => {
            if (res.id) {
              Toast({
                message: '您已加入团队',
                iconClass: 'icon icon-success',
                duration: 5 * 1000
              });
            }
            removeGroupCode();
            window.history.replaceState({}, 0, process.env.VUE_APP_BASE_href);
          }).catch((res) => {
            removeGroupCode();
            window.history.replaceState({}, 0, process.env.VUE_APP_BASE_href);
          })
        }
      })
    },
    //获取当前用户信息
    getMyGroup({
      commit
    }) {
      return new Promise((resolve, reject) => {
        myGroup().then((response)=>{
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
};

export default user;