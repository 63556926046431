import request from '@/utils/request'

// 获取我的奖品  /lottery/Activity_20220618/getMyAwardsByUserId

export function getMyAwards(params) {
    return request({
        url: '/lottery/Activity_20220618/getMyAwardsByUserId',
        method: 'get',
        params
    })
}

// 编辑领奖信息  /lottery/Activity_20220618/editReceiveInfo

export function editReceiveInfo(data) {
    return request({
        url:'/lottery/Activity_20220618/editReceiveInfo',
        method: 'post',
        data
    })
}

// 获取领奖信息  /lottery/{activityCode}/getReceiveInfoById

export function getReceiveInfo(params) {
    return request({
        url: '/lottery/Activity_20220618/getReceiveInfoById/'+params,
        method: 'get',
    })
}