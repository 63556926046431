import request from '@/utils/request'


//微信登录
export function wxLogin(params) {
    return request({
        url:'/wxlogin/wx',
        method: 'get',
        params
    })
}

//获取刷新token
export function refreshToken(params) {
    return request({
        url: '/wxlogin/getToken',
        method: 'get',
        params
    })
}

//链接点击日志
export function channelPoint(data) {
    return request({
        url: '/api/log_link',
        method: 'POST',
        data
    })
}