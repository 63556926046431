import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import gameIndex from '@/views/game/index.vue'
import rule from '@/views/game/rule.vue'
import start from '@/views/game/start.vue'
import lottery from '@/views/lottery/index.vue'
import myPrize from '@/views/lottery/myPrize.vue'
import info from '@/views/lottery/info.vue'
import test from '@/views/test.vue'

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 **/

const routes = [{
    path: '*/',
    name:'start',
    redirect: '/'
  },
  {
    path: '/game', //游戏页面
    component: gameIndex,
    name:'gameIndex',
    hidden: true
  },
  {
    path: '/rule', //游戏规则页面
    component: rule,
    name:'rule',
    hidden: true
  },
  {
    path: '/', //游戏开始页面
    component: start,
    name:'start',
    hidden: true
  },{
    path: '/lottery', //抽奖页面
    component: lottery,
    name:'lottery',
    hidden: true
  },{
    path: '/myPrize', //我的奖品
    component: myPrize,
    name:'myPrize',
    hidden: true
  },{
    path: '/info', //领奖信息
    component: info,
    name:'info',
    hidden: true
  },{
    path: '/test', //清除缓存
    component: test,
    name:'test',
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
