<template>
  <div class="rule">
   游戏规则页面
  </div>
</template>

<script>
export default {
  name: "rule",
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
};
</script>
<style lang="scss" scoped>
</style>
