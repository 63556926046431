<template>
  <div id="app">
    <div class="app-info">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods:{
    
  }
};
</script>
<style lang="scss" scoped>
</style>
