<template>
  <div class="gameIndex">
    <div class="gameIndex-bgTop" v-if="!showPop">
      <div class="info">
        <p class="time">
          <span class="sp">剩余时间</span>
          <span>{{ count }}秒</span>
        </p>
        <p class="num">
          <span class="sp">{{ allAmountReal[0] }}</span>
          <span class="sp">{{ allAmountReal[1] }}</span>
          <span class="sp">{{ allAmountReal[2] }}</span>
        </p>
        <p class="tips">
          已获得积分
        </p>
      </div>
    </div>
    <div class="content" id="gameBox" v-if="!showPop"></div>
    <mt-popup v-model="showPop">
      <div class="mask">
        <img class="decorate" src="@/assets/image/game-endBg.png" alt="" />
        <div class="m-wrap">
          <img class="m-title" src="@/assets/image/game-maskTitle.png" alt="" />
          <div class="m-info1">
            <img
              class="m-icon"
              src="@/assets/image/gameIntegral-icon.png"
              alt=""
            />
            <span class="m-text">+</span>
            <span class="m-text"
              >{{ allAmountReal[0] ? allAmountReal[0] : ""
              }}{{ allAmountReal[1] ? allAmountReal[1] : ""
              }}{{ allAmountReal[2] }}分</span
            >
          </div>
          <div class="m-info2">
            <img class="m-icon" src="@/assets/image/game-maskTip.png" alt="" />
            <span class="m-text">抽奖机会+1</span>
          </div>
        </div>
        <div class="goToLottery">
          <img
            class="m-btn"
            src="@/assets/image/game-goToLottery.png"
            alt=""
            @click="linkTo"
          />
          <img class="m-hand" src="@/assets/image/game-hand.png" alt="" />
        </div>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { redPack } from "@/utils/rain";
import { addOnceLottery, myCount } from "@/api/game";
export default {
  name: "gameIndex",
  data() {
    return {
      count: 15, //倒计时
      allAmount: 0, //获取的积分
      allAmountReal: [0, 0, 0],
      redIdArr: [], //抢到的个数容器
      redAmountArr: [], //获取的积分总数
      showPop: false, //是否出现积分弹窗
      timer: "", //积分倒计时
      rain: null, //定义全局事件
    };
  },
  mounted() {
    this.startGame();
    this.getData();
  },
  methods: {
    getData() {
      //生成所有积分球
      var data = [];
      const _that = this;
      for (var i = 1; i < 300; i++) {
        data.push({
          id: i,
          amount: 2,
          className: i % 5,
        });
      }
      setTimeout(() => {
        _that.rain.start(data);
      }, 50);
    },
    startGame() {
      const _that = this;
      const _allAmountReal = [0, 0, 0];
      //开始启动游戏
      if (_that.rain) return;
      _that.rain = new redPack({
        el: document.getElementById("gameBox"), // 容器
        speed: 55, // 速度，越小越快
        density: 300, //  红包密度，越小越多
        callback: (e) => {
          // 点击红包的回调
          _that.redIdArr.push(e.target.getAttribute("redId"));
          //计算积分
          var redAmountOne = e.target.getAttribute("redAmount");
          _that.allAmount = _that.allAmount + Number(redAmountOne);
          var reg = /\d{1,1}(?=(\d{1})+$)/g;
          var allAmountReal = (_that.allAmount + "").replace(reg, "$&,");
          var allAmountRealArr = allAmountReal.split(",");
          if (allAmountRealArr.length == 1) {
            _allAmountReal[2] = allAmountRealArr[0];
             _that.allAmountReal = JSON.parse(JSON.stringify(_allAmountReal));
          } else if (allAmountRealArr.length == 2) {
            _allAmountReal[1] = allAmountRealArr[0];
            _allAmountReal[2] = allAmountRealArr[1];
             _that.allAmountReal = JSON.parse(JSON.stringify(_allAmountReal));
          } else if (allAmountRealArr.length == 3) {
            _that.allAmountReal = JSON.parse(JSON.stringify(allAmountRealArr));
          }
        },
      });
      //倒计时
      _that.timer = setInterval(function () {
        _that.count--;
        if (_that.count <= 0) {
          clearInterval(_that.timer);
          _that.rain.stop();
          _that.rain.clear();
          setTimeout((res) => {
            _that.showPop = true;
          }, 1000);
        }
      }, 1000);
    },
    linkTo() {
      //跳转到领奖页面
      this.myCount();
    },
    //增加一次抽奖机会
    addOnceLottery() {
      addOnceLottery().then((res) => {
        this.$router.replace({
          path: "/lottery",
        });
      });
    },
    // 我的抽奖次数
    myCount() {
      myCount().then((res) => {
        if (!res.awardTotal) {
          this.addOnceLottery();
        } else {
          this.$router.replace({
            path: "/lottery",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.gameIndex {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/image/game-bg.jpg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  .gameIndex-bgTop {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    padding-top: 0.3rem;
    box-sizing: border-box;
    z-index: 10000;
    background-image: url("../../assets/image/game-bgTop.jpg");
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;
  }
  .info {
    width: 3.5rem;
    height: 2.16rem;
    margin: 0 auto 0.2rem;
    background-image: url("../../assets/image/game-border.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    color: #ffffff;
    text-align: center;
    padding: 0.25rem 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    .time {
      span {
        font-size: 0.28rem;
        font-weight: bold;
        &.sp {
          margin-right: 0.28rem;
        }
      }
    }
    .num {
      margin: 0.2rem 0px 0.1rem;
      .sp {
        width: 0.4rem;
        height: 0.6rem;
        text-align: center;
        line-height: 0.6rem;
        display: inline-block;
        vertical-align: middle;
        background: #ffffff;
        border-radius: 0.04rem;
        -webkit-border-radius: 0.04rem;
        font-size: 0.4rem;
        color: #1d1db9;
        margin: 0px 0.05rem;
      }
    }
    .tips {
      font-size: 0.24rem;
      font-weight: bold;
    }
  }

  //游戏界面
  //弹窗界面
  .mask {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 3.3rem 0px 0px;
    box-sizing: border-box;
    text-align: center;
    .decorate {
      width: 100%;
      height: auto;
      position: absolute;
      top: 1rem;
      left: -0.7rem;
      z-index: 1000;
    }
    .m-wrap {
      width: 5.29rem;
      height: 5.73rem;
      position: relative;
      background: url("../../assets/image/game-maskBg.png") center top no-repeat;
      background-size: 100% 100%;
      margin: 0px auto;
      .m-title {
        position: absolute;
        width: 2.62rem;
        top: -0.31rem;
        left: 50%;
        transform: translateX(-50%);
      }
      .m-info1 {
        padding: 0.89rem 0.78rem 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        .m-icon {
          width: 1.22rem;
          height: 1.35rem;
        }
        .m-text {
          font-size: 0.48rem;
          font-weight: bold;
          color: #200f60;
        }
      }
      .m-info2 {
        padding: 0.79rem 0.78rem 0px;
        box-sizing: border-box;
        text-align: center;
        .m-icon {
          width: 100%;
          height: auto;
          margin-bottom: 0.1rem;
        }
        .m-text {
          font-size: 0.3rem;
          font-weight: bold;
          color: #200f60;
        }
      }
    }
    .goToLottery {
      width: 100%;
      margin-top: 0.25rem;
      position: relative;
      .m-btn {
        width: 3rem;
        height: auto;
        margin: 0px auto;
      }
      .m-hand {
        width: 1.5rem;
        height: auto;
        position: absolute;
        right: 1.53rem;
        top: 0.25rem;
        animation: fingerHandle 1s ease infinite both;
      }
    }
  }
}

@keyframes fingerHandle {
  0% {
    transform: none;
  }
  70% {
    transform: scale3d(0.8, 0.8, 0.8);
  }
  100% {
    transform: none;
  }
}
</style>
<style lang="scss">
.gameIndex {
  .content {
    width: 100%;
    height: 100%;
    position: relative;
    #redpack {
      position: absolute;
      display: block;
      width: 1.25rem;
      height: 1.39rem;
    }
    .redpack0 {
      background: url("../../assets/image/game-fun1.png") no-repeat 0 0;
      background-size: cover;
    }
    .redpack1 {
      background: url("../../assets/image/game-fun2.png") no-repeat 0 0;
      background-size: cover;
    }
    .redpack2 {
      background: url("../../assets/image/game-fun3.png") no-repeat 0 0;
      background-size: cover;
    }
    .redpack3 {
      background: url("../../assets/image/game-fun4.png") no-repeat 0 0;
      background-size: cover;
    }
    .redpack4 {
      background: url("../../assets/image/game-fun5.png") no-repeat 0 0;
      background-size: cover;
    }
    /*点击后的红包*/
    #redpacked {
      background: transparent;
      background-size: cover;
    }
    /* 空的红包 */
    #redPackNone {
      display: none;
    }
  }
  .mint-popup {
    width: 100%;
    height: 100%;
    background: transparent;
  }
}
</style>
