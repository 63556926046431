<template>
  <div class="test"></div>
</template>

<script>
import { removeToken, removeRefshToken, removeUser } from "@/utils/auth";
export default {
  name: "lottery",
  data() {
    return {};
  },
  created() {
    removeToken();
    removeRefshToken();
    removeUser();
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>