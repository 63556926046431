import request from '@/utils/request'


// 开盲盒   /lottery/Activity_20220618/getAwardsByIndex

export function openAward(data) {
    return request({
        url:'/lottery/Activity_20220618/getAwardsByIndex',
        method: 'post',
        data
    })
}


// 增加抽奖次数   /useraward/11/game/add_once

export function addOnceLottery(data) {
    return request({
        url:'/useraward/Activity_20220618/game/add_once',
        method: 'post',
        data
    })
}

// 获取游戏次数  /useraward/11/my_count

export function myCount(params) {
    return request({
        url: '/useraward/Activity_20220618/my_count',
        method: 'get',
        params
    })
}

// 加入战队
export function joinGroup(data) {
    return request({
        url: `/activity/Activity_20220618/group/invite/${data.groupCode}`,
        method: 'POST',
    })
}

//获取战队code
export function myGroup(params) {
    return request({
        url: '/activity/Activity_20220618/group',
        method: 'get',
        params
    })
}