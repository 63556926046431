<template>
  <div class="myPrize">
    <div class="content">
      <img
        class="title"
        src="https://fs.zhbim.com/imgs/active/618/my-prize-title.png"
        alt=""
      />
      <p class="hint">试用期将在信息完善后直接发放，虚拟/实物奖品请添加企业微信领取；</p>
      <p class="hint">请在<span class="emphasize">2022年6月30日前</span>完善资料，逾期将视为放弃领奖哦~</p>
      <div class="list">
        <div class="item" v-for="(item, index) in prizeList" :key="index">
          <img class="goods" :src="item.thumbnailUrl" alt="" />
          <div class="mid">
            <p class="goods-name">{{ item.name }}</p>
            <p class="time">{{ item.createdTime }}</p>
          </div>
          <div
            class="btn"
            @click="linkTo(item)"
            :class="{ disable: !item.status && disable }"
          >
            {{ item.status ? "查看详情" : "去领取" }}
          </div>
        </div>
      </div>
      <div class="gotoweix" @click="weixMask=true">添加企业微信</div>
    </div>
    <mt-popup v-model="weixMask">
      <div class="weixMask">
        <img src="@/assets/image/weixin.jpg" class="weixin"/>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { Toast } from "mint-ui";
import { getMyAwards } from "@/api/lottery";
export default {
  data() {
    return {
      weixMask:false,
      prizeList: [],
      disable: false,
    };
  },
  created() {
    this.timeDifference();
    this.getPrizeList();
  },
  methods: {
    getPrizeList() {
      getMyAwards().then((res) => {
        this.prizeList = res;
        console.log(res);
      });
    },
    linkTo(item) {
      if (!item.status && this.disable) {
        Toast({
          message: "奖品已过期",
          duration: 3 * 1000,
        });
        return;
      }
      this.$router.push({
        path: "/info?status=" + item.status + "&id=" + item.id,
      });
    },
    timeDifference() {
      let timestamp = new Date().getTime();
      let timestamp1 = this.getTargetTime("2022-07-01 00:00:00");
      let deta = Number(timestamp) - Number(timestamp1);
      if (deta >= 0) {
        this.disable = true;
      }
    },
    //获取指定时间戳
    getTargetTime(t) {
      var d = t.split(" ")[0],
        h = t.split(" ")[1],
        date = new Date();

      date.setYear(d.split("-")[0]);
      date.setMonth(d.split("-")[1] - 1);
      date.setDate(d.split("-")[2]);

      date.setHours(h.split(":")[0]);
      date.setMinutes(h.split(":")[1]);
      date.setSeconds(h.split(":")[2]);

      return date.getTime();
    },
  },
};
</script>
<style lang="scss" scoped>
.myPrize {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/image/game-bg.jpg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 2.12rem;
  box-sizing: border-box;
  .weixMask{
    width: 5rem;
    background: #ffffff;
    text-align: center;
    padding: 0.1rem;
    padding-bottom: 0px;
    box-sizing: border-box;
    .weixin{
      width: 100%;
    }
  }
  .content {
    margin: 0 auto;
    width: 6.69rem;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
    padding: 0.5rem 0.33rem;
    box-sizing: border-box;
    .title {
      width: 2.25rem;
      margin-bottom: 0.2rem;
    }
    .hint {
      text-align: left;
      font-size: 0.24rem;
      font-weight: 500;
      color: #888888;
      line-height: 0.36rem;
    }
    .emphasize {
      color: red;
    }
    .list {
      margin-top: 0.4rem;
      overflow: scroll;
      max-height: 6rem;
      .item {
        text-align: left;
        display: flex;
        background: #ffffff;
        border: 1px solid #251876;
        border-radius: 4px;
        padding: 0.28rem 0.37rem 0.34rem 0.15rem;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.4rem;
        img {
          width: 1.14rem;
          height: 0.95rem;
        }
        .mid {
          height: 0.95rem;
          width: 2.8rem;
          font-size: 0.24rem;
          color: #251876;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .time {
            opacity: 0.6;
          }
        }
        .btn {
          width: 1.2rem;
          height: 0.42rem;
          border: 1px solid #251876;
          border-radius: 0.21rem;
          line-height: 0.4rem;
          text-align: center;
          font-size: 0.18rem;
          font-weight: 500;
          color: #251876;
        }
        .disable {
          color: #999;
          border: 1px solid #999;
        }
      }
    }
    .gotoweix{
      display: inline-block;
      font-size: 0.28rem;
      border-radius: 0.2rem;
      background: #858fec;
      color: #ffffff;
      padding: 0.15rem 0.5rem;
      cursor: pointer;
    }
  }
}
</style>