//接口封装的方法
import axios from 'axios'
import {
  getToken,
  setToken,
  removeToken,
  getRefshToken,
  setRefshToken,
  removeRefshToken,
  getUser
} from '@/utils/auth'
import {
  refreshToken
} from "@/api/login";
import {
  Toast
} from 'mint-ui';
import store from "@/store";
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: 180000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
  }
});

function result(response, res) { //结果内容
  if (response.config.url == 'wxlogin/wx') { //给微信登录的接口做特殊处理
    Toast({
      message: response.data.message || response.data.msg,
      iconClass: 'icon icon-error',
      duration: 5 * 1000
    });
    return Promise.reject(res)
  } else if (res.code === 401 && response.config.url != 'wxlogin/getToken') {
    return new Promise((resolve, reject) => {
      if (getUser()) {
        removeToken();
        removeRefshToken();
        refreshToken({
          account: getUser().account,
          password: getUser().passwordHash
        }).then((res) => {
          setToken(res.access_token);
          setRefshToken(res.refresh_token)
          store.commit('SET_TOKEN', res.access_token)
          store.commit('SET_REFSHTOKEN', res.refresh_token)
          service({
            params: response.config.params,
            data: response.config.data,
            method: response.config.method,
            url: response.config.url
          }).then((res) => {
            resolve(res)
          })
        }).catch(() => {
          reject("")
        });
      }
    })
  } else if (res.code === 1035 || res.code === 1033 || res.code === 401 && response.config.url == 'wxlogin/getToken') {
    Toast({
      message: 'token已过期，请重新授权',
      iconClass: 'icon icon-error',
      duration: 5 * 1000
    });
    removeToken();
    removeRefshToken();
    removeGroupCode();
    store.commit('SET_TOKEN', null)
    store.commit('SET_REFSHTOKEN', null)
    store.commit('SET_GROUPCODE', null)
    location.replace(process.env.VUE_APP_BASE_RefshHref);
  } else if (res.code === 1002) { //用户不存在的时候，清空缓存
    removeToken();
    removeRefshToken();
    removeGroupCode();
    store.commit('SET_TOKEN', null)
    store.commit('SET_REFSHTOKEN', null)
    store.commit('SET_GROUPCODE', null)
    Toast({
      message: res.message || res.msg,
      iconClass: 'icon icon-error',
      duration: 5 * 1000
    });
    return Promise.reject(res)
  } else {
    Toast({
      message: res.message || res.msg,
      iconClass: 'icon icon-error',
      duration: 5 * 1000
    });
    return Promise.reject(res)
  }
}
// request拦截器
service.interceptors.request.use(config => {
  if (getToken()) {
    config.headers['Authorization'] = 'Bearer ' + getToken()
  }
  if (getRefshToken()) {
    config.headers['X-Authorization'] = 'Bearer ' + getRefshToken()
  }
  if (!config.params) {
    config.params = {}
  }
  return config
}, error => {
  console.log('request_error:' + error) // for debug
  Promise.reject(error)
})
// respone拦截器
service.interceptors.response.use(
  response => {
    const {
      status,
      statusText,
      data
    } = response
    if (status != 200) {
      Toast({
        message: statusText,
        iconClass: 'icon icon-error',
        duration: 5 * 1000
      });
      return Promise.reject('error')
    }

    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = data
    if (res.code != 0) {
      result(response, res)
    }
    return res.data;
  },
 async error => {
    const _result=await result(error.response, error.response.data);
    console.log("_result",_result)
    if(_result){
      return Promise.resolve(_result)
    }
    if(error.response.data.code === 401){
      return Promise.reject(error)
    }else if (error && error.response) {
      Toast({
        message: error.response.data.message || error.response.data.msg,
        iconClass: 'icon icon-error',
        duration: 5 * 1000
      });
      return Promise.reject(error.response)
    } else {
      Toast({
        message: error.message || error.msg,
        iconClass: 'icon icon-error',
        duration: 5 * 1000
      });
      return Promise.reject(error)
    }
  }
)

export default service