<template>
  <div class="lottery">
    <div class="title-wrap">
      <img
        src="https://fs.zhbim.com/imgs/active/618/lottery-title.png"
        alt=""
      />
      <p class="tiem">（活动时间：6月16日-6月26日）</p>
      <div class="line-wrap">
        <div class="left"></div>
        <div class="right"></div>
      </div>
    </div>
    <div class="panel-wrap">
      <div class="list" v-if="!isRunLottery">
        <img
          v-for="index in 9"
          :key="index"
          class="item"
          @click="openPrize(index)"
          :class="cur == index - 1 ? 'cur' : ''"
          src="https://fs.zhbim.com/imgs/active/618/blind-box.png"
          alt=""
        />
      </div>
      <div class="list" v-else>
        <img
          v-for="(item, index) in runLotteryList"
          :key="index"
          class="item"
          :src="item"
          alt=""
        />
      </div>
      <p class="text1">
        点击上方盲盒参与抽奖，剩余 <span>{{ awardCount }}</span> 次抽奖
      </p>
      <p class="text2">邀请好友可额外获得 <span>1</span> 次抽奖机会</p>
    </div>
    <div class="btn-wrap">
      <img
        v-if="MyLotteryNumber.shareable"
        src="https://fs.zhbim.com/imgs/active/618/invite-btn.png"
        @click="linkTo(1)"
        alt=""
      />
      <img
        v-else
        src="https://fs.zhbim.com/imgs/active/618/disable_invite.png"
        alt=""
      />
      <img
        src="https://fs.zhbim.com/imgs/active/618/prize-btn.png"
        @click="linkTo(2)"
        alt=""
      />
    </div>
    <p class="interpretation">
      © 活动最终解释权归中恒信息科技（广州）有限公司所有
    </p>
    <mt-popup
      v-model="prizePopupVisible"
      :closeOnClickModal="MyLotteryNumber.inventory === 0"
    >
      <div class="popup-wrap popup-wrap-prize">
        <div class="content">
          <img
            class="title"
            src="https://fs.zhbim.com/imgs/active/618/prize-title.png"
            alt=""
          />

          <img
            class="prize"
            @click="popupVisible = false"
            :src="prizeImage"
            alt=""
          />
          <p class="text" v-if="MyLotteryNumber.shareable">
            邀请好友获取一次抽奖机会<span @click="linkTo(1)">立即邀请</span>
          </p>
        </div>
        <img
          class="popup-bg"
          src="https://fs.zhbim.com/imgs/active/618/popup_bg.png"
          alt=""
        />
        <img
          class="btn"
          src="https://fs.zhbim.com/imgs/active/618/get-prize-btn.png"
          alt=""
          @click="linkTo(2)"
        />
        <img
          v-if="MyLotteryNumber.inventory > 0"
          class="btn"
          src="https://fs.zhbim.com/imgs/active/618/try_again.png"
          alt=""
          @click="linkTo(3)"
        />
      </div>
    </mt-popup>
    <mt-popup v-model="invitePopupVisible">
      <div class="popup-wrap">
        <div class="content">
          <img
            class="title"
            src="https://fs.zhbim.com/imgs/active/618/invite-title.png"
            alt=""
          />

          <div class="link-wrap">
            <p class="text1">您的专属邀请链接：</p>
            <p class="text2">{{ groupLink }}</p>
            <p class="text3">*好友点开链接可获得1次机会，多次点击不做累积</p>
          </div>
          <!-- <p class="text">*每个人最多可获得一次机会，多次点击不做累积</p> -->
        </div>
        <img
          class="popup-bg"
          src="https://fs.zhbim.com/imgs/active/618/popup_bg.png"
          alt=""
        />
        <img
          class="btn copyBtn"
          src="https://fs.zhbim.com/imgs/active/618/copy-btn.png"
          alt=""
          @click="onCopy"
        />
      </div>
    </mt-popup>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { Toast } from "mint-ui";
import { openAward, myCount } from "@/api/game";
export default {
  name: "lottery",
  data() {
    return {
      groupLink: "",
      prizePopupVisible: false,
      invitePopupVisible: false,
      awardCount: 1, //抽奖次数
      cur: null, //盲盒样式
      shareable: true,
      prizeImage: "",
      runLotteryList: [],
      isRunLottery: false,
      MyLotteryNumber: {},
    };
  },
  created() {
    this.myCount();
  },
  methods: {
    onCopy() {
      //复制邀请链接
      var t = this;
      var clipboard = new Clipboard(".copyBtn", {
        text: function (trigger) {
          return t.groupLink;
        },
      });
      clipboard.on("success", (e) => {
        Toast({
          message: "已成功复制链接，赶紧发给好友一起抽奖吧！",
          duration: 3 * 1000,
        });
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        Toast({
          message: "该浏览器不支持自动复制",
          duration: 3 * 1000,
        });
        clipboard.destroy();
      });
    },
    openPrize(index) {
      //开盲盒
      this.cur = index - 1;
      openAward({
        index: this.cur,
      }).then((res) => {
        if (res) {
          this.prizeImage = res.coverUrl;
          this.runLotteryList = res.thumbnailUrl;
          this.myCount();
          setTimeout(() => {
            this.isRunLottery = true;
            this.prizePopupVisible = true;
          }, 800);
        }
      });
    },
    linkTo(type) {
      if (type === 1) {
        this.$store.dispatch("getMyGroup").then((res) => {
          this.groupLink =
            process.env.VUE_APP_BASE_href + "?groupCode=" + res.id;
          this.prizePopupVisible = false;
          this.invitePopupVisible = true;
        });
      } else if (type === 2) {
        if (
          this.MyLotteryNumber.awardTotal === this.MyLotteryNumber.inventory
        ) {
          Toast({
            message: "请抽奖后再查看我的奖品",
            duration: 3 * 1000,
          });
          return;
        }
        this.$router.push({
          path: "/myPrize",
        });
      } else if (type === 3) {
        if (this.MyLotteryNumber.inventory > 0) {
          this.isRunLottery = false;
          this.prizePopupVisible = false;
          this.cur = null;
        }
      }
    },
    //获取我的抽奖次数
    myCount() {
      myCount().then((res) => {
        this.MyLotteryNumber = res;
        this.awardCount = res.inventory;
        this.shareable = res.shareable;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lottery {
  width: 100vw;
  min-height: 100vh;
  background-image: url("https://fs.zhbim.com/imgs/active/618/lottery-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 1.82rem;
  box-sizing: border-box;
  position: relative;
  .title-wrap {
    width: 6.83rem;
    height: 2rem;
    margin: 0 0.33rem;
    background-image: url("https://fs.zhbim.com/imgs/active/618/lottery-border1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    img {
      position: absolute;
      top: -0.85rem;
      left: 0.82rem;
      width: 5.3rem;
    }
    .tiem {
      width: 100%;
      position: absolute;
      bottom: 0.3rem;
      left: 0;
      color: #fff;
      text-align: center;
    }
    .line-wrap {
      position: absolute;
      bottom: -0.25rem;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.15rem;
      div {
        width: 0.21rem;
        height: 0.32rem;
        background: #ffffff;
        opacity: 0.4;
      }
    }
  }
  .panel-wrap {
    width: 6.71rem;
    height: 6.7rem;
    margin: 0 0.4rem;
    margin-top: 0.24rem;
    background-image: url("https://fs.zhbim.com/imgs/active/618/lottery-border2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 0.46rem;
    padding-top: 0.55rem;
    text-align: center;
    color: #fff;
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 1.75rem;
        height: 1.49rem;
        margin-bottom: 0.27rem;
      }
      .cur {
        animation-iteration-count: 1;
        animation-duration: 0.5s;
        animation-fill-mode: both;
        animation-name: aroundAnimation;
        transform-origin: bottom;
      }
    }
    .text1 {
      font-size: 0.24rem;
      line-height: 0.24rem;
      margin-bottom: 0.2rem;
      span {
        color: #75f0ff;
      }
    }
    .text2 {
      font-size: 0.2rem;
      line-height: 0.2rem;
      span {
        color: #75f0ff;
      }
    }
  }
  .btn-wrap {
    padding: 0.52rem 0.43rem 0.56rem 0.43rem;
    display: flex;
    justify-content: space-between;
    img {
      width: 3.01rem;
      height: 0.96rem;
    }
  }
  .interpretation {
    position: absolute;
    bottom: 0.32rem;
    left: 0;
    width: 100%;
    text-align: center;
    color: #ffffff;
    opacity: 0.6;
    font-size: 0.16rem;
  }
  .mint-popup {
    background-color: transparent;
  }
  .popup-wrap {
    width: 7.5rem;
    position: relative;
    text-align: center;
    .content {
      margin: 0 auto;
      width: 4.84rem;
      height: 4.41rem;
      background: linear-gradient(0deg, #8548e9 0%, #9f78ff 100%);
      border-radius: 0.2rem;
      position: relative;
      padding-top: 0.8rem;
      box-sizing: border-box;
      .title {
        width: 2.62rem;
        position: absolute;
        top: -0.22rem;
        left: 50%;
        margin-left: -1.31rem;
      }
      .prize {
        width: 3.73rem;
        // margin-top: 0.58rem;
        margin-left: 0.04rem;
      }
      .link-wrap {
        text-align: left;
        margin: 0 auto;
        width: 3.83rem;
        height: 2.91rem;
        background: #f1f6ff;
        box-shadow: 5px 0px 13px 0px #4e1cb0;
        border-radius: 0.1rem;
        padding: 0.3rem;
        box-sizing: border-box;
        margin-bottom: 0.15rem;
        .text1 {
          font-size: 0.28rem;
          font-weight: bold;
          color: #3c0a90;
          line-height: 0.52rem;
          margin-bottom: 0.1rem;
        }
        .text2 {
          font-size: 0.2rem;
          font-weight: 500;
          color: #385eef;
          width: 3.29rem;
          height: 0.8rem;
          background: #e8eef8;
          border-radius: 4px;
          padding: 0.2rem 0.1rem;
          word-break: break-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .text3 {
          font-size: 0.24rem;
          font-weight: 500;
          color: #3c0a90;
          margin-top: 0.2rem;
        }
      }
      .text {
        text-align: center;
        color: #ffffff;
        font-weight: 500;
        font-size: 0.2rem;
        span {
          color: #52e1ff;
          margin-left: 0.08rem;
          margin-left: 0.2rem;
        }
        .arrows {
          margin-left: 0.08rem;
          font-size: 0.3rem;
        }
      }
    }
    .popup-bg {
      width: 6.88rem;
      position: absolute;
      top: 0.2rem;
      left: 0.3rem;
    }
    .btn {
      width: 3rem;
      height: 0.96rem;
      margin-top: 0.35rem;
    }
  }
}
@keyframes aroundAnimation {
  0%,
  50%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  25% {
    transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
    transform: rotate(-25deg) translate3d(0.35rem, 0, 0);
  }

  75% {
    transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
    transform: rotate(25deg) translate3d(-0.35rem, 0, 0);
  }
}
</style>
<style lang="scss">
.popup-wrap-prize {
  .content {
    padding-top: 0.58rem !important;
  }
}
.mint-toast {
  z-index: 100000000;
}
</style>