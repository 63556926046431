<template>
  <div class="start">
    <img
      class="banner"
      src="https://fs.zhbim.com/imgs/active/618/banner-start.jpg"
      alt=""
    />
    <div class="btn-wrap">
      <div class="btn-flex" v-if="lotteryInfo">
        <div class="btn-box" @click="linkTo">
          <span class="text">{{ btnInfo.text }}</span>
          <img
            class="start-btn"
            src="https://fs.zhbim.com/imgs/active/618/start-btn.png"
            alt=""
          />
        </div>
        <div
          class="btn-box"
          @click="linkToPrize"
          v-if="
            (lotteryInfo.awardTotal === 1 &&
              lotteryInfo.inventory === 0 &&
              lotteryInfo.shareable) ||
            (lotteryInfo.awardTotal === 2 &&
              (lotteryInfo.inventory === 1 || lotteryInfo.inventory === 2) &&
              !lotteryInfo.shareable)
          "
        >
          <span class="text">我的奖品</span>
          <img
            class="start-btn"
            src="https://fs.zhbim.com/imgs/active/618/start-btn.png"
            alt=""
          />
        </div>
      </div>
      <p class="rule-btn" @click="popupVisible = true">游戏规则》</p>
    </div>
    <mt-popup v-model="popupVisible">
      <div class="rule-wrap">
        <img
          class="rule"
          src="https://fs.zhbim.com/imgs/active/618/rule.png"
          alt=""
        />
        <img
          class="close"
          @click="popupVisible = false"
          src="https://fs.zhbim.com/imgs/active/618/close.png"
          alt=""
        />
        <p class="rule-text">
          ①游戏时间为15s；<br />②点击建模助手模块越多，抽奖手气越好；<br />③每个用户仅有1次机会，分享好友可额外获得1次机会；即每人最多有2次抽奖机会；<br />④请添加“建模助手”企业微信领取奖品。
        </p>
      </div>
    </mt-popup>
    <mt-popup v-model="invitePopupVisible">
      <div class="popup-wrap">
        <div class="content">
          <img
            class="title"
            src="https://fs.zhbim.com/imgs/active/618/invite-title.png"
            alt=""
          />

          <div class="link-wrap">
            <p class="text1">您的专属邀请链接：</p>
            <p class="text2">{{ groupLink }}</p>
            <p class="text3">*好友点开链接可获得1次机会，多次点击不做累积</p>
          </div>
          <!-- <p class="text">*好友点开链接可获得1次机会，多次点击不做累积</p> -->
        </div>
        <img
          class="popup-bg"
          src="https://fs.zhbim.com/imgs/active/618/popup_bg.png"
          alt=""
        />
        <img
          class="btn copyBtn"
          src="https://fs.zhbim.com/imgs/active/618/copy-btn.png"
          alt=""
          @click="onCopy"
        />
      </div>
    </mt-popup>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { myCount } from "@/api/game";
import { Toast } from "mint-ui";
export default {
  name: "start",
  data() {
    return {
      groupLink: "",
      lotteryInfo: {},
      btnInfo: {
        text: "玩游戏赢好礼",
        type: 1,
      },
      popupVisible: false,
      invitePopupVisible: false,
    };
  },
  created() {},
  mounted() {
    this.$store.dispatch("getInvite"); //判断是否需要加入团队\
    this.myCount();
  },
  methods: {
    onCopy() {
      //复制邀请链接
      var t = this;
      var clipboard = new Clipboard(".copyBtn", {
        text: function (trigger) {
          return t.groupLink;
        },
      });
      clipboard.on("success", (e) => {
        Toast({
          message: "已成功复制链接，赶紧发给好友一起抽奖吧！",
          duration: 3 * 1000,
        });
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        Toast({
          message: "该浏览器不支持自动复制",
          duration: 3 * 1000,
        });
        clipboard.destroy();
      });
    },
    getRule() {
      this.popupVisible = true;
    },
    linkToPrize() {
      //跳转我的奖品页面
      this.$router.push({
        path: "/myPrize",
      });
    },
    linkTo() {
      switch (this.btnInfo.type) {
        case 1:
          //跳转游戏界面
          this.$router.push({
            path: "/game",
          });
          break;
        case 2:
          this.$store.dispatch("getMyGroup").then((res) => {
            this.groupLink =
              process.env.VUE_APP_BASE_href + "?groupCode=" + res.id;
            //邀请链接
            this.invitePopupVisible = true;
          });
          break;
        case 3:
          //跳转我的奖品界面
          this.$router.push({
            path: "/myPrize",
          });
          break;
        case 4:
          //跳转抽奖页
          this.$router.push({
            path: "/lottery",
          });
          break;
      }
    },
    // 我的抽奖次数
    myCount() {
      myCount().then((res) => {
        if (res) {
          this.lotteryInfo = res;
          this.btnHandle(res);
        }
      }).catch((res) => {
        // return Promise.reject(data);
      });
    },
    // 按钮状态处理
    btnHandle(res) {
      if (res) {
        if (!res.awardTotal) {
          this.btnInfo.text = "玩游戏赢好礼";
          this.btnInfo.type = 1;
        } else if (
          res.awardTotal === 1 &&
          res.inventory === 0 &&
          res.shareable
        ) {
          this.btnInfo.text = "邀请好友";
          this.btnInfo.type = 2;
        } else if (res.awardTotal === 2 && res.inventory === 0) {
          this.btnInfo.text = "我的奖品";
          this.btnInfo.type = 3;
        } else if (
          res.awardTotal === 1 &&
          res.inventory === 1 &&
          res.shareable
        ) {
          this.btnInfo.text = "已玩直接抽奖";
          this.btnInfo.type = 4;
        } else if (
          res.awardTotal === 2 &&
          (res.inventory === 1 || res.inventory === 2) &&
          !res.shareable
        ) {
          this.btnInfo.text = "再来一次";
          this.btnInfo.type = 1;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.start {
  width: 100vw;
  max-width: 750px;
  height: 100vh;
  background-image: url("https://fs.zhbim.com/imgs/active/618/bg-start.png");
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  position: relative;

  .banner {
    width: 100%;
    max-width: 750px;
    height: auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  .btn-wrap {
    position: absolute;
    left: 0;
    bottom: 0.45rem;
    width: 100vw;
    max-width: 750px;
    z-index: 99;
    text-align: center;
    .btn-box {
      position: relative;
      .start-btn {
        width: 3.83rem;
      }
      .text {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 0.37rem;
        left: 0;
        font-size: 0.32rem;
        font-weight: bold;
        color: #ffffff;
        line-height: 0.32rem;
      }
    }
    .rule-btn {
      color: #ffffff;
      font-size: 0.2rem;
    }
  }
  .mint-popup {
    background-color: transparent;
  }
  .rule-wrap {
    text-align: center;
    position: relative;
    .rule {
      width: 6.2rem;
    }
    .close {
      width: 0.64rem;
      height: 0.64rem;
      margin-top: 0.45rem;
    }
    .rule-text {
      text-align: left;
      color: #ffffff;
      font-size: 0.24rem;
      line-height: 0.4rem;
      position: absolute;
      left: 0.62rem;
      bottom: 3.4rem;
      width: 5.14rem;
    }
  }
  .popup-wrap {
    width: 7.5rem;
    position: relative;
    text-align: center;
    .content {
      margin: 0 auto;
      width: 4.84rem;
      height: 4.41rem;
      background: linear-gradient(0deg, #8548e9 0%, #9f78ff 100%);
      border-radius: 0.2rem;
      position: relative;
      padding-top: 0.8rem;
      box-sizing: border-box;
      .title {
        width: 2.62rem;
        position: absolute;
        top: -0.22rem;
        left: 50%;
        margin-left: -1.31rem;
      }
      .prize {
        width: 3.73rem;
        // margin-top: 0.58rem;
        margin-left: 0.04rem;
      }
      .link-wrap {
        text-align: left;
        margin: 0 auto;
        width: 3.83rem;
        height: 2.91rem;
        background: #f1f6ff;
        box-shadow: 5px 0px 13px 0px #4e1cb0;
        border-radius: 0.1rem;
        padding: 0.3rem;
        box-sizing: border-box;
        margin-bottom: 0.15rem;
        .text1 {
          font-size: 0.28rem;
          font-weight: bold;
          color: #3c0a90;
          line-height: 0.52rem;
          margin-bottom: 0.1rem;
        }
        .text2 {
          font-size: 0.2rem;
          font-weight: 500;
          color: #385eef;
          width: 3.29rem;
          height: 0.8rem;
          background: #e8eef8;
          border-radius: 4px;
          padding: 0.2rem 0.1rem;
          word-break: break-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .text3 {
          font-size: 0.24rem;
          font-weight: 500;
          color: #3c0a90;
          margin-top: 0.2rem;
        }
      }
      .text {
        text-align: center;
        color: #ffffff;
        font-weight: 500;
        font-size: 0.2rem;
        span {
          color: #52e1ff;
          margin-left: 0.08rem;
          margin-left: 0.2rem;
        }
        .arrows {
          margin-left: 0.08rem;
          font-size: 0.3rem;
        }
      }
    }
    .popup-bg {
      width: 6.88rem;
      position: absolute;
      top: 0.2rem;
      left: 0.3rem;
    }
    .btn {
      width: 3rem;
      height: 0.96rem;
      margin-top: 0.35rem;
    }
  }
}
</style>
<style>
.mint-toast {
  z-index: 100000000;
}
</style>
 