import Vue from 'vue'
import App from './App.vue';
import router from './router'
import store from "./store";

import "babel-polyfill";
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.use(MintUI);

import '@/static/common.css';

import './permission';

// 在 iOS中，点击输入框之后无法获取焦点问题
import FastClick from 'fastclick'
if ('addEventListener' in document) {
  document.addEventListener('DOMContentLoaded', function () {
    FastClick.attach(document.body);
  }, false);
}

FastClick.prototype.focus = function (targetElement) {
  let length;
  if (
    targetElement.setSelectionRange &&
    targetElement.type.indexOf("date") !== 0 &&
    targetElement.type !== "time" &&
    targetElement.type !== "month"
  ) {
    length = targetElement.value.length;
    targetElement.focus();
    targetElement.setSelectionRange(length, length);
  } else {
    targetElement.focus();
  }
}

//判断是否使用Mock
console.log('[Main]  NODE_ENV:', process.env.NODE_ENV, 'Mock:', process.env.VUE_APP_MOCK);
if (process.env.NODE_ENV !== 'production' && process.env.VUE_APP_MOCK === 'true') {
  require('./mock/index.js');
  console.log('[Main] Mock loaded.');
}

//全局引入微信jssdk
import wx from 'weixin-js-sdk';
Vue.prototype.$wx = wx;

import axios from "axios";
Vue.config.productionTip = false
new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')

