var g = function (id) {
    return document.getElementById(id);
};

export function redPack(options) {
    this.el = options.el;
    this.rains = [];
    this.speed = options.speed; // 控制红包落下的速度
    this.density = options.density; // 红包密度
    this.callback = options.callback; // 回调
}

redPack.prototype.create = function (id, amount, className) {
    var el = this.el,
        that = this,
        fragment = document.createDocumentFragment(),
        nRed = document.createElement("span");

    // 添加自定义属性
    nRed.setAttribute("redId", id);
    nRed.setAttribute("redAmount", amount);
    nRed.className = "redpack" + className;
    nRed.id = "redpack";
    nRed.style.left = Math.random() * (el.clientWidth - 50) + "px";
    nRed.style.top = -el.clientHeight / 10 + "px";

    fragment.appendChild(nRed);
    el.appendChild(fragment);
    that.rains.push(nRed);
    that.move(nRed);

};
redPack.prototype.start = function (data) {
    let that = this,
        i = 0;
    that.timer = setInterval(function () {
        if (i <= data.length - 1) {
            var id = data[i].id,
                amount = data[i].amount,
                className = data[i].className
            that.create(id, amount, className);
            i++;
        }

    }, that.density);
    // 红包事件函数
    const _handler = (e, t) => {
        if (e.target.id !== "redpack redpacked") {
            e.target.id = "redpack redpacked";
            t.callback(e);
        }
    };
    that.myEvent = function (e) {
        if (e.target.id === 'redpack') {
            _handler.call(that, e, that);
        } else if (e.target.getAttribute('redAmount') === '0') {
            e.target.id = 'redPackNone';
        } else {
            return false;
        }
    }
    document.addEventListener('touchstart', that.myEvent);
};

redPack.prototype.stop = function () {
    var This = this;
    clearInterval(This.timer);
    for (var i = 0; i < This.rains.length; i++) {
        clearInterval(This.rains[i].timer);
    }
    document.removeEventListener('touchstart', This.myEvent);
};
redPack.prototype.move = function (rains) {
    var el = this.el;
    var This = this;
    var diffY = Math.random() + 1; // 垂直上的轻微偏移
    var diffX = Math.random(); // 水平上的轻微偏移
    rains.timer = setInterval(function () {
        rains.style.left = parseInt(rains.style.left) - parseInt(diffX * rains.clientHeight / 80) + "px";
        rains.style.top = parseInt(rains.style.top) + parseInt(diffY * rains.clientHeight / 10) + "px";
        if (el.clientHeight < parseInt(rains.style.top)) {
            // 超出 区域过后，关闭定时器，删除红包
            clearInterval(rains.timer);
            el.removeChild(rains);
        }
    }, This.speed);
};
// 时间停止时清除剩余红包
redPack.prototype.clear = function () {
    var container = g('gameBox'),
        redItem = container.childNodes;
    for (var i = redItem.length - 1; i >= 0; i--) {
        container.removeChild(redItem[i]);
    }
};