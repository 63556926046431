import Vue from 'vue';
import Vuex from 'vuex';
import getters from '@/store/getters'

Vue.use(Vuex);

const modulesFiles = require.context('./modules', true, /\.js$/);
const modules = modulesFiles.keys().reduce((mods, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  mods[moduleName] = value.default;
  return mods;
}, {});

export default new Vuex.Store({
  modules,
  getters: getters,
});
